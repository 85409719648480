// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-blog-post-layout-js": () => import("./../../../src/layouts/BlogPostLayout.js" /* webpackChunkName: "component---src-layouts-blog-post-layout-js" */),
  "component---src-layouts-product-layout-js": () => import("./../../../src/layouts/ProductLayout.js" /* webpackChunkName: "component---src-layouts-product-layout-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-detector-js": () => import("./../../../src/pages/app-detector.js" /* webpackChunkName: "component---src-pages-app-detector-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-ecwid-upsell-checkout-privacy-policy-js": () => import("./../../../src/pages/ecwid-upsell-checkout-privacy-policy.js" /* webpackChunkName: "component---src-pages-ecwid-upsell-checkout-privacy-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-license-js": () => import("./../../../src/pages/license.js" /* webpackChunkName: "component---src-pages-license-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */)
}

